.banner {
    background-color: #fed757;
    color: black;
}

.blink {
width: 200px;
height: 50px;
background-color: white;
padding: 15px;
color: black; 
text-align: center;
line-height: 50px;
}

.blink-span {
color: black;
animation: blink 3s linear infinite;
}

@keyframes blink {
0% {
    opacity: 0;
}

15% {
    opacity: 0.5;
}

50% {
    opacity: 1;
    color: #C23373;
}

70% {
    opacity: 0.5;
}

100% {
    opacity: 0;
}
}