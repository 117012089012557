.Button-terrible{
    color: black !important;
    background-color: #ff9f1c !important;
    border: #ff9f1c;
}
.Button-terrible:active {
    color: black !important;
    background-color: blue !important;
    border: blue !important; 
}

.Button-bad {
    color: black !important;
    background-color: #ffbf69 !important;
    border: #ffbf69;
}

.Button-okay {
    color: black !important;
    background-color: #fff3b0 !important;
    border: #fff3b0;
}

.Button-good {
    color: black !important;
    background-color: #cbf3f0 !important;
    border: #cbf3f0;
}

.Button-excellent {
    color: black !important;
    background-color: #2ec4b6 !important;
    border: #2ec4b6;
}