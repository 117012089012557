@tailwind base;
@tailwind components;
@tailwind utilities;

html, body, #root {
  height: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100%;
}