@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
     
.accordion-body {
    display: none;
   
}

.accordion input:checked + div {
    display: block;
}   
}

.table-row-basic{
    border-color: grey;
    border-style: solid;
    border-width: 2px;
    height: 5%;
    color: black;
    margin-bottom: 1%;
}

.badge-neutral {
    background-color: lightgrey;
   
}

.badge-header {
    color: black !important;
    background-color: darkgray !important;
    height: 100% !important;
    text-align: center !important;

}

.badge-terrible {
    color: black !important;
    background-color: #ff9f1c !important;
    height: 100% !important;

}

.badge-bad {
    color: black !important;
    background-color: #ffbf69 !important;
    height: 100% !important;

}

.badge-medium {
    color: black !important;
    background-color: #fff3b0 !important;

}

.badge-good {
    color: black !important;
    background-color: #cbf3f0 !important;

}

.badge-excellent {
    color: black !important;
    background-color: #2ec4b6 !important;

}